<template>
  <div>
    <!-- <this-header /> -->
    <div class="anan-set-nav-conttainer3 mt-150">
      <router-link :to="{ name: 'new-pages' }">
        <b-button class="btn btn-gradient-primary mb-1 mr-50 mr-50">
          <i class="fas fa-arrow-left mr-25" /> {{ $t("key-126") }}
        </b-button>
      </router-link>
      <b-button class="btn btn-gradient-primary mb-1" @click="Createpayment()">
        + {{ $t("createPaymentSlip") }}
      </b-button>
      <b-overlay
        :show="showOver"
        variant="transparent"
        opacity="0.99"
        blur="5px"
        rounded="sm"
      >
        <template #overlay>
          <div class="text-center">
            <p id="cancel-label">กรุณารอสักครู่...</p>
          </div>
        </template>
        <b-row class="match-height">
          <b-col md="3">
            <div class="card p-2 bg-gradient-custom-orange">
              <div class="d-flex align-items-center justify-content-between">
                <div>
                  <!-- <i class="fas fa-user-edit fa-3x" /> -->
                  <img
                    src="@/assets/images/anan-img/payment/number item.svg"
                    alt="member code"
                    height="70"
                  />
                </div>

                <div class="text-right">
                  <h3 class="text-white">
                    {{ $t("itemCount") }}
                  </h3>

                  <h4 class="text-white">
                    {{ Commas(numSelect) }}
                  </h4>
                </div>
              </div>
            </div>
          </b-col>

          <b-col md="3">
            <div class="card p-2 bg-gradient-custom-ware">
              <div class="d-flex align-items-center justify-content-between">
                <div>
                  <!-- <i class="fas fa-cart-arrow-down fa-3x" /> -->
                  <img
                    src="@/assets/images/anan-img/payment/must pay.svg"
                    alt="member code"
                    height="70"
                  />
                </div>

                <div class="text-right">
                  <h3 class="text-white">
                    {{ $t("amountDue") }}
                  </h3>

                  <h4 class="text-white">
                    {{ Commas(paymentSelect) }}
                  </h4>
                </div>
              </div>
            </div>
          </b-col>

          <b-col md="3">
            <div
              class="card p-1 bg-gradient-custom-indigo position-relative overflow-hidden"
            >
              <div class="d-flex justify-content-between align-items-center">
                <h5 class="text-white">
                  {{ $t("key-182") }}
                </h5>

                <span class="text-white head text-center p-">
                  <b-spinner small variant="warning" type="grow" />
                  {{ $t("key-183") }}
                </span>
              </div>

              <div v-if="itemlotType1.length" class="d-flex flex-wrap">
                <b-badge
                  v-for="(item, index) in itemlotType1"
                  :key="index"
                  class="cursor-pointer"
                  variant="danger"
                  style="z-index: 1; margin-right: 2px; margin-bottom: 3px"
                >
                  {{ $t("truck") }}/{{ item.name_lot }}
                </b-badge>
              </div>
              <div v-else class="text-white head text-center mt-2">
                ไม่มีตู้เข้าไทย
              </div>
              <img src="/icon/car.svg" alt="car" class="p-image" height="60" />
            </div>
          </b-col>

          <b-col md="3">
            <div
              class="card p-1 bg-gradient-custom-pink position-relative overflow-hidden"
            >
              <div class="d-flex justify-content-between align-items-center">
                <h5 class="text-white">
                  {{ $t("key-182") }}
                </h5>

                <span class="text-white head text-center">
                  <b-spinner small variant="warning" type="grow" />
                  {{ $t("key-184") }}
                </span>
              </div>

              <div v-if="itemlotType2.length" class="d-flex flex-wrap">
                <b-badge
                  v-for="(item, index) in itemlotType2"
                  :key="index"
                  class="cursor-pointer"
                  variant="warning"
                  style="z-index: 1; margin-right: 2px; margin-bottom: 3px"
                >
                  {{ $t("ship") }}/{{ item.name_lot }}
                </b-badge>
              </div>
              <div v-else class="text-white head text-center mt-2">
                ไม่มีตู้เข้าไทย
              </div>
              <img
                src="/icon/ship.svg"
                alt="ship"
                class="p-image"
                height="60"
              />
            </div>
          </b-col>
        </b-row>

        <b-row class="match-height">
          <b-col md="12">
            <div class="card">
              <div
                class="header-cardx p-2 d-flex align-items-center row"
                style="gap: 10px"
              >
                <div
                  class="d-flex align-items-center justify-content-between auto-pos"
                >
                  <div>
                    <b-input-group>
                      <b-form-input
                        id="basic-password"
                        v-model="search"
                        :placeholder="$t('SearchParcels')"
                        @keyup.enter="getDataPayment()"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          icon="SearchIcon"
                          class="cursor-pointer"
                          @click="getDataPayment()"
                        />
                      </b-input-group-append>
                    </b-input-group>
                  </div>
                </div>
                <div
                  class="d-flex align-items-center justify-content-between auto-pos"
                >
                  <div>
                    <b-input-group>
                      <b-form-input
                        id="basic-password"
                        v-model="keyword"
                        :placeholder="$t('mnemonic')"
                        @keyup.enter="getDataPayment()"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          icon="SearchIcon"
                          class="cursor-pointer"
                          @click="getDataPayment()"
                        />
                      </b-input-group-append>
                    </b-input-group>
                  </div>
                </div>
                <div
                  class="d-flex align-items-center justify-content-between auto-pos"
                >
                  <div>
                    <b-input-group>
                      <b-form-input
                        id="basic-password"
                        v-model="user_member"
                        :placeholder="$t('memberCode')"
                        @keyup.enter="getDataPayment()"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          icon="SearchIcon"
                          class="cursor-pointer"
                          @click="getDataPayment()"
                        />
                      </b-input-group-append>
                    </b-input-group>
                  </div>
                </div>

                <div class="d-flex align-items-center auto-pos">
                  <div style="width: 150px">{{ $t("productLot") }} :</div>
                  <b-form-select
                    v-model="lotselect"
                    :options="itemslot"
                    @change="getDataPayment()"
                  />
                </div>

                <b-form-checkbox
                  id="checkbox-1"
                  v-model="status"
                  name="checkbox-1"
                  :value="true"
                  :unchecked-value="false"
                  class="auto-pos"
                  @change="getDataPayment()"
                >
                  {{ $t("key-327") }}
                </b-form-checkbox>
                <b-button class="btn btn-gradient-danger auto-pos" @click="DeleteBox()">
                   {{ $t("key") }}
                </b-button>
              </div>
              <div class="table-container">
                <table id="customers" width="100%">
                  <thead>
                    <tr>
                      <th scope="col">
                        <b-form-checkbox
                          v-model="selectAll"
                          @change="isIndeterminate()"
                        />
                      </th>
                      <!-- <th scope="col" /> -->
                      <th scope="col" style="min-width: 125px !important">
                        {{ $t("sequence") }}
                      </th>
                      <th scope="col">
                        {{ $t("parcelCode") }}
                      </th>
                      <th scope="col">
                        {{ $t("orderList") }}
                      </th>
                      <th v-if="colspan_filed === 5" scope="col">
                        {{ $t("memberCode") }}
                      </th>
                      <th scope="col" style="min-width: 180px !important">
                        {{ $t("mnemonic") }}
                      </th>
                      <th scope="col">
                        {{ $t("quantity") }}
                      </th>
                      <th scope="col">
                        {{ $t("weight") }}
                      </th>
                      <th scope="col">
                        {{ $t("widthCm") }}
                      </th>
                      <th scope="col">
                        {{ $t("heightCm") }}
                      </th>
                      <th scope="col">
                        {{ $t("lengthCm") }}
                      </th>
                      <th scope="col">
                        {{ $t("cubic") }}
                      </th>
                      <th scope="col">
                        {{ $t("type") }}
                      </th>
                      <th scope="col">
                        {{ $t("serviceFee") }}
                      </th>
                      <th scope="col">
                        {{ $t("arrivedChinaWarehouse") }}
                      </th>
                      <th scope="col">
                        {{ $t("leftChinaWarehouse") }}
                      </th>
                      <th scope="col">
                        {{ $t("arrivedThaiWarehouse") }}
                      </th>
                      <!-- <th scope="col">
                    ถึงไทย
                  </th> -->
                      <th scope="col">
                        {{ $t("pricePerKilo") }}
                      </th>
                      <th scope="col">
                        {{ $t("pricePerCubic") }}
                      </th>
                    </tr>
                  </thead>
                  <thead>
                    <tr>
                      <td scope="col" colspan="1" style="background: #ebeff2" />
                      <th
                        :colspan="colspan_filed"
                        style="
                          background: #ebeff2;
                          color: black;
                          text-align: right;
                        "
                      >
                        <div class="text-right">
                          <b>{{ $t("total") }}</b>
                        </div>
                      </th>
                      <th
                        scope="col"
                        style="background: #ffc1c18a; color: black"
                      >
                        <b>{{ totalSum ? Commas(totalSum.amountTotal) : 0 }}</b>
                      </th>
                      <th
                        scope="col"
                        style="background: #ffc1c18a; color: black"
                      >
                        <b>{{ totalSum ? Commas(totalSum.weightTotal) : 0 }}</b>
                      </th>
                      <th
                        scope="col"
                        style="background: #ffc1c18a; color: black"
                      >
                        <b>{{ totalSum ? Commas(totalSum.widthTotal) : 0 }}</b>
                      </th>
                      <th
                        scope="col"
                        style="background: #ffc1c18a; color: black"
                      >
                        <b>{{ totalSum ? Commas(totalSum.heightTotal) : 0 }}</b>
                      </th>
                      <th
                        scope="col"
                        style="background: #ffc1c18a; color: black"
                      >
                        <b>{{ totalSum ? Commas(totalSum.longTotal) : 0 }}</b>
                      </th>
                      <th
                        scope="col"
                        style="background: #ffc1c18a; color: black"
                      >
                        <b>{{ totalSum ? CommasCue(totalSum.cueTotal) : 0 }}</b>
                      </th>
                      <th scope="col" colspan="1" style="background: #ebeff2" />
                      <th
                        scope="col"
                        style="background: #ffc1c18a; color: black"
                      >
                        <b>{{
                          totalSum ? Commas(totalSum.serviceTotal) : 0
                        }}</b>
                      </th>
                      <th scope="col" colspan="3" style="background: #ebeff2" />

                      <th
                        scope="col"
                        style="background: #ffc1c18a; color: black"
                      >
                        <b>{{
                          totalSum ? Commas(totalSum.charge_kiloTotal) : 0
                        }}</b>
                      </th>
                      <th
                        scope="col"
                        style="background: #ffc1c18a; color: black"
                      >
                        <b>{{
                          totalSum ? Commas(totalSum.charge_cueTotal) : 0
                        }}</b>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in items"
                      :key="index"
                      :class="`ctx-${checkedcolor(item)}`"
                    >
                      <td scope="col" class="text-center">
                        <b-form-checkbox
                          v-if="item.come_thaiwarehouse"
                          v-model="item.selectItem"
                          :value="true"        
                          @input="isIndeterminateItem(item)"
                        />
                      </td>
                      <!-- <td scope="col">
                        <feather-icon
                          icon="Edit3Icon"
                          class="mr-50 text-primary cursor-pointer"
                          @click="EditType(item)"
                        />
                      </td> -->
                      <td scope="col" align="left">
                        <span
                          v-if="item.transportType === 1"
                          class="text-danger font-weight-bolder"
                        >
                          ({{ $t("truck") }})
                        </span>
                        <span
                          v-if="item.transportType === 2"
                          class="text-warning font-weight-bolder"
                        >
                          ({{ $t("ship") }})
                        </span>
                        {{ item.lot_name
                        }}{{ item.lot_order ? `/${item.lot_order}` : "" }}
                      </td>
                      <td
                        scope="col"
                        class="text-left"
                        style="width: 20% !important"
                      >
                        <div
                          class="d-flex justify-content-between align-items-center"
                        >
                          <router-link
                            :to="{
                              name: 'tracking-edit',
                              params: { id: item._id },
                            }"
                          >
                            {{ item.postnumber }}
                          </router-link>
                          <div
                            class="d-flex"
                            style="overflow: auto; max-width: 150px"
                          >
                            <span
                              v-for="imageOrAll in item.path_img"
                              :key="imageOrAll"
                              class="preview-image-container d-flex justify-content-center"
                              style="height: 30px"
                            >
                              <img
                                :src="GetImg('postnumber_img', imageOrAll)"
                                alt="Preview"
                                class=""
                                @click="
                                  showPicture('postnumber_img', item.path_img)
                                "
                              />
                            </span>
                          </div>
                        </div>
                      </td>
                      <td
                        scope="col"
                        class="text-left"
                        style="width: 7% !important"
                      >
                        <router-link
                          v-if="item.order_text"
                          :to="{
                            name: 'order-detail',
                            params: { id: item.order_id },
                          }"
                          class="mb-0"
                        >
                          {{ `ORD-${item.order_text}` }}
                        </router-link>
                        <span v-else> - </span>
                      </td>
                      <td v-if="colspan_filed === 5" scope="col">
                        {{ item.user_member ? item.user_member : "" }}
                      </td>
                      <td scope="col" style="width: 20% !important">
                        <input
                          ref="textarea"
                          v-model="item.keyword"
                          rows="1"
                          class="ant-input ghost ant-input-sm sss form-control-plaintext"
                          @change="SubmitKeyword(item._id, item.keyword)"
                        />
                        <!-- {{ item.keyword ? item.keyword : ''}} -->
                      </td>
                      <td scope="col">
                        {{ Commas(item.product_amount) }}
                      </td>
                      <td scope="col">
                        {{ Commas(item.weight * item.product_amount) }}
                      </td>
                      <td scope="col">
                        {{ Commas(item.width) }}
                      </td>
                      <td scope="col">
                        {{ Commas(item.height) }}
                      </td>
                      <td scope="col">
                        {{ Commas(item.long) }}
                      </td>
                      <td scope="col">
                        {{ CommasCue(item.charge_cue * item.product_amount) }}
                      </td>
                      <td scope="col" style="width: 1% !important">
                        {{
                          item.product_type ? item.product_type.pro_initial : ""
                        }}
                      </td>
                      <td scope="col">
                        {{
                          Commas(
                            item.product_inspection_fee +
                              item.shipping_fee +
                              item.re_wrap_fee +
                              item.packaging_fee +
                              item.other
                          )
                        }}
                      </td>
                      <td
                        scope="col"
                        style="color: #0a7b20; width: 5% !important"
                      >
                        <b>{{
                          item.come_chinesewarehouse
                            ? item.come_chinesewarehouse
                            : "-"
                        }}</b>
                      </td>
                      <td
                        scope="col"
                        style="color: #0a7b20; width: 5% !important"
                      >
                        <b>{{
                          item.out_chinesewarehouse
                            ? item.out_chinesewarehouse
                            : "-"
                        }}</b>
                      </td>
                      <td
                        scope="col"
                        style="color: #0a7b20; width: 5% !important"
                      >
                        <b>
                          {{
                            item.come_thaiwarehouse
                              ? item.come_thaiwarehouse
                              : "-"
                          }}</b
                        >
                      </td>
                      <!-- <td scope="col">
                    {{ item.out_thaiwarehouse ? item.out_thaiwarehouse: '-' }}
                  </td> -->
                      <td scope="col">
                        <span
                          v-if="
                            item.discount &&
                            item.charge_per_kilo === item.charge_per_save
                          "
                        >
                          {{ Commas(item.charge_per_kilo)
                          }}<span class="text-danger"
                            >-{{ Commas(item.discount) }}%
                            {{
                              Commas(
                                (item.charge_per_save * (100 - item.discount)) /
                                  100
                              )
                            }}</span
                          >
                        </span>
                        <span v-else>{{
                          item.charge_per_kilo === item.charge_per_save
                            ? Commas(item.charge_per_save)
                            : "-"
                        }}</span>
                        <!-- {{ item.charge_per_kilo === item.charge_per_save ? Commas(item.charge_per_save): '-' }} -->
                      </td>
                      <td scope="col">
                        <span
                          v-if="
                            item.discount &&
                            item.charge_per_cue === item.charge_per_save
                          "
                        >
                          {{ Commas(item.charge_per_cue)
                          }}<span class="text-danger"
                            >-{{ Commas(item.discount) }}%
                            {{
                              Commas(
                                (item.charge_per_save * (100 - item.discount)) /
                                  100
                              )
                            }}</span
                          >
                        </span>
                        <span v-else>{{
                          item.charge_per_cue === item.charge_per_save
                            ? Commas(item.charge_per_save)
                            : "-"
                        }}</span>
                        <!-- {{ item.charge_per_cue === item.charge_per_save ? Commas(item.charge_per_save): '-' }} -->
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td scope="col" colspan="1" style="background: #ebeff2" />
                      <td
                        class="text-right"
                        :colspan="colspan_filed"
                        style="background: #ebeff2; color: black"
                      >
                        <b>{{ $t("total") }}</b>
                      </td>
                      <td
                        class="text-center"
                        scope="col"
                        style="background: #ffc1c18a; color: black"
                      >
                        <b>{{ totalSum ? Commas(totalSum.amountTotal) : 0 }}</b>
                      </td>
                      <td
                        class="text-center"
                        scope="col"
                        style="background: #ffc1c18a; color: black"
                      >
                        <b>{{ totalSum ? Commas(totalSum.weightTotal) : 0 }}</b>
                      </td>
                      <td
                        class="text-center"
                        scope="col"
                        style="background: #ffc1c18a; color: black"
                      >
                        <b>{{ totalSum ? Commas(totalSum.widthTotal) : 0 }}</b>
                      </td>
                      <td
                        class="text-center"
                        scope="col"
                        style="background: #ffc1c18a; color: black"
                      >
                        <b>{{ totalSum ? Commas(totalSum.heightTotal) : 0 }}</b>
                      </td>
                      <td
                        class="text-center"
                        scope="col"
                        style="background: #ffc1c18a; color: black"
                      >
                        <b>{{ totalSum ? Commas(totalSum.longTotal) : 0 }}</b>
                      </td>
                      <td
                        class="text-center"
                        scope="col"
                        style="background: #ffc1c18a; color: black"
                      >
                        <b>{{ totalSum ? CommasCue(totalSum.cueTotal) : 0 }}</b>
                      </td>
                      <td scope="col" colspan="1" style="background: #ebeff2" />
                      <td
                        class="text-center"
                        scope="col"
                        style="background: #ffc1c18a; color: black"
                      >
                        <b>{{
                          totalSum ? Commas(totalSum.serviceTotal) : 0
                        }}</b>
                      </td>
                      <td scope="col" colspan="3" style="background: #ebeff2" />

                      <td
                        scope="col"
                        class="text-center"
                        style="background: #ffc1c18a; color: black"
                      >
                        <b>{{
                          totalSum ? Commas(totalSum.charge_kiloTotal) : 0
                        }}</b>
                      </td>
                      <td
                        scope="col"
                        class="text-center"
                        style="background: #ffc1c18a; color: black"
                      >
                        <b>{{
                          totalSum ? Commas(totalSum.charge_cueTotal) : 0
                        }}</b>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <div
                class="d-flex align-items-center justify-content-between p-2"
              >
                <!-- <span class="czx ctx-yellow">
                  {{ $t('checkGoods') }}
                </span> -->

                <div class="d-flex">
                  <span class="czx ctx-green">
                    {{ $t("checkOrder") }}
                  </span>

                  <span class="czx ctx-orange ml-2">
                    {{ $t("woodPacking") }}
                  </span>

                  <span class="czx ctx-red ml-2">
                    {{ $t("noPrice") }}
                  </span>
                </div>

                <div class="d-flex">
                  <b-form-select
                    id="perPageSelect"
                    v-model="perPage"
                    class="mr-1"
                    size="sm"
                    inline
                    :options="pageOptions"
                    @change="getDataPayment()"
                  />
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    first-number
                    last-number
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mb-0"
                    @input="getDataPayment()"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>

                <!-- <span class="czx ctx-purple">
                claim
              </span> -->
              </div>
            </div>
          </b-col>
        </b-row>
      </b-overlay>
    </div>
    <vue-image-lightbox-carousel
      ref="lightbox"
      :show="showLightbox"
      :images="images"
      :show-caption="false"
      @close="closeBox()"
    />
    <h2 class="text-center text-danger text-dang">
      **** เราชั่งน้ำหนัก และวัดขนาดตามจริง "คิดราคาแบบไหนได้มากกว่าคิดแบบนั้น"
      **** <br />
      **** หากไม่แจ้งชำระค่าขนส่งเกิน 45 วัน สินค้าจะถูกนำออกจำหน่าย
      เพื่อนำมาชดเชยต้นทุนในการนำเข้า ****
    </h2>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from "moment-timezone";
import {
  // BModal,
  // BOverlay,
  // BIconController,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BFormCheckbox,
  BFormTextarea,
  BSpinner,
  // vSelect,
  BInputGroupAppend,
  BInputGroup,
  BOverlay,
  BFormSelect,
  BPagination,
  BFormGroup,
  BBadge,
} from "bootstrap-vue";
// import ThisHeader from '@/views/customer/component/ThisHeader.vue'
import VueImageLightboxCarousel from "vue-image-lightbox-carousel";

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BButton,
    BFormCheckbox,
    // vSelect,
    BInputGroupAppend,
    BInputGroup,
    // ThisHeader,
    BOverlay,
    BFormSelect,
    BPagination,
    BSpinner,
    BFormGroup,
    VueImageLightboxCarousel,
    BBadge,
  },
  data() {
    return {
      itemlotType1: [],
      itemlotType2: [],
      selected: {
        value: null,
        text: "ทั้งหมด",
        disabled: true,
        selected: true,
      },
      status: false,
      options: [
        {
          value: null,
          text: "เลือก",
          disabled: true,
          selected: true,
        },
        { value: "1", text: "ล็อต 1" },
        { value: "2", text: "ล็อต 2" },
        { value: "3", text: "ล็อต 3" },
      ],
      type: "A",
      lotselect: "ทั้งหมด",
      itemsSelect: [
        { value: "A" },
        { value: "B" },
        { value: "C" },
        { value: "D" },
        { value: "D1" },
        { value: "S" },
      ],
      items: [],
      show: false,
      showOverlay: false,
      imgList: "",
      search: null,
      isActive: "all",
      selectedRadio: "active",
      optionsRadio: [
        { text: "All", value: "all" },
        { text: "Car", value: "car" },
        { text: "Ship", value: "ship" },
      ],
      pageOptions: [100, 200, 300, 400, 500],
      currentPage: 1,
      perPage: 100,
      totalRows: 1,
      // items: [],
      pic: null,

      total: 0,
      waitingforcheck: 0,
      waitingforpayment: 0,
      paid: 0,
      OrderSuccess: 0,
      completetrack: 0,
      completewarehouse: 0,
      cancel: 0,
      Checknumber: [],
      numberlength: 0,
      payment: 0,
      selectAll: false,
      checkitems: 0,
      arritems: [],
      showOver: false,
      itemslot: [],
      keyword: null,
      user_member: null,
      colspan_filed: 4,
      userData: JSON.parse(localStorage.getItem("userData")),
      images: [],
      showLightbox: false,
      totalSum: null,
      payData: [],
    };
  },
  computed: {
    // isIndeterminate() {
    //   const checkedCount = this.selectItem.filter(item => item).length
    //   return checkedCount > 0 && checkedCount < this.selectItem.length
    // },
    numSelect() {
      return this.payData.filter((item) => item.selectItem).length;
    },
    paymentSelect() {
      return this.payData
        .filter((item) => item.selectItem)
        .reduce((total, item) => {
          if (item.discount) {
            return total + (item.charge_per_save * (100 - item.discount)) / 100;
          }
          return total + item.charge_per_save;
        }, 0);
    },
    // filteredItems() {
    //   return this.items.filter(item => {
    //     // Filter by postnumber
    //     const matchesSearch = this.search
    //       ? new RegExp(this.search, 'i').test(item.postnumber)
    //       : true

    //     // Filter by keyword
    //     const matchesKeyword = this.keyword
    //       ? new RegExp(this.keyword, 'i').test(item.keyword)
    //       : true

    //     // Filter by user_member
    //     const matchesUserMember = this.user_member
    //       ? new RegExp(this.user_member, 'i').test(item.user_member)
    //       : true

    //     // Filter by lot_name
    //     const matchesLotName = this.lotselect !== 'ทั้งหมด'
    //       ? new RegExp(this.lotselect, 'i').test(item.lot_name)
    //       : true

    //     // Filter by status_th
    //     const matchesStatusTh = this.status
    //       ? item.come_thaiwarehouse
    //       : true

    //     return (
    //       matchesSearch
    //       && matchesKeyword
    //       && matchesUserMember
    //       && matchesLotName
    //       && matchesStatusTh
    //     )
    //   })
    // },
  },
  watch: {
    // selectItem: {
    //   handler() {
    //     this.updateCheckAllState()
    //   },
    //   deep: true,
    // },
  },
  async mounted() {
    await this.GetLotName();
    // this.GetData(this.$route.params.id)
    // this.sub_role = []
    // this.GetSubrole()
    // this.GetModuleList()
    // this.GetGroupUserList()
    this.getDataPayment();
    this.checkRole();
    this.GetLottoDay();
    localStorage.setItem("paymentItems", []);
  },
  methods: {
    async GetLotName() {
      const { data: res } = await this.$http.get(
        "CustomerPayment/getData/lotname"
      );
      this.itemslot = res.data;
      this.itemslot.unshift("ทั้งหมด");
    },
    async GetLottoDay() {
      try {
        const { data: res } = await this.$http.get("/Lotorder/Tothai");
        // this.itemlot = res
        if (!res.inthai.checkNothai) {
          const itemlotType1 = res.data.filter(
            (item) => item.transportType === 1
          );
          const itemlotType2 = res.data.filter(
            (item) => item.transportType === 2
          );
          this.itemlotType1 = itemlotType1;
          this.itemlotType2 = itemlotType2;
        }

        // console.log(res)
      } catch (err) {
        console.log(err);
      }
    },
    GetImg(pathfile, filename) {
      const matches = filename.match(
        /^data:image\/([A-Za-z-+\/]+);base64,(.+)$/
      );
      if (!matches || matches.length !== 3) {
        return `${this.$http.defaults.baseURL}/viewimg?pathfile=${pathfile}/${filename}`;
      }
      return filename;
    },
    showPicture(pathfile, picture) {
      this.showLightbox = true;
      picture.forEach((picture) => {
        const img = { path: this.GetImg(pathfile, picture) };
        this.images.push(img);
      });
    },
    closeBox() {
      this.showLightbox = false;
      this.images = [];
    },
    checkRole() {
      if (
        this.userData.ability.some(
          (ele) =>
            ele.subject === "agent_user" || ele.subject === "agent_tracking"
        )
      ) {
        this.colspan_filed = 5;
      }
    },
    time(data) {
      return moment(data).format("YYYY/MM/DD HH:mm:ss");
    },
    Pay(data) {
      this.$swal({
        title: "ยืนยันที่จะชำระเงิน ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.show = true;
          const params = {
            // eslint-disable-next-line no-underscore-dangle
            ID: data._id,
          };

          this.$http
            .post("/order/confirmpay", params)
            // eslint-disable-next-line no-unused-vars
            .then((response) => {
              // console.log(response.data)
              this.Success("ชำระเงินสำเร็จ");
              this.show = false;
              this.GetNotify();
              this.GetOrderAll();
            })
            .catch((error) => {
              this.SwalError(error.response.data.message);
              this.show = false;
            });
        } else {
          this.$swal("ยกเลิก", "ยกเลิกคำสั่ง !", "error");
        }
      });
    },
    checkedcolor(item) {
      if (item.packbox && item.checkproduct) {
        return "yellow";
      }
      if (item.checkproduct) {
        return "green";
      }
      if (item.packbox) {
        return "orange";
      }
      if (item.charge_per_save === 0) {
        return "red";
      }
      return "#ffffff";
    },
    async getDataPayment() {
      this.showOver = true;
      try {
        const params = {
          page: this.currentPage,
          perPage: this.perPage,
          post: this.search,
          keyword: this.keyword,
          user_member: this.user_member,
          lot_name: this.lotselect,
          status: this.status,
        };
        const { data: res } = await this.$http.get(
          "/CustomerPayment/getData/payment",
          { params }
        );
        const itemres = res.data.map((item) => {
          // eslint-disable-next-line no-param-reassign
          if (this.payData.length) {
            const existingItem = this.payData.find(
              (existing) => existing._id == item._id
            );
            if (existingItem) {
              item.selectItem = true;
            }
          } else {
            item.selectItem = false;
          }
          return item;
        });
        this.totalSum = res.totalSum;
        this.items = itemres;
        this.selectAll = false;
        // this.itemslot = Array.from(new Set(this.items.map(item => item.lot_name)))
        this.totalRows = res.total;
        this.showOver = false;
        console.debug(res);
      } catch (e) {
        console.log(e);
        this.showOver = false;
      }
    },
    // CheckDate() {
    //   const params = {
    //     search: this.search, // 1
    //     lot_name: this.lotselect, // 4
    //     keyword: this.keyword, // 2
    //     user_member: this.user_member, // 3
    //     status_th: this.status, // 5
    //   }
    //   console.debug(params)
    //   // let filterItems = [...this.main_items]
    //   // if (this.search) {
    //   //   filterItems = filterItems.filter
    //   // }
    //   // this.$http.get('CustomerPayment/getData/Search', { params })
    //   //   .then(response => {
    //   //     const itemres = response.data.data.map(item => {
    //   //     // eslint-disable-next-line no-param-reassign
    //   //       item.selectItem = false
    //   //       return item
    //   //     })
    //   //     this.items = itemres
    //   //     this.totalRows = response.data.total
    //   //     // console.debug(response.data)
    //   //   })
    // },
    isIndeterminate() {
      this.items.map((item) => {
        if (this.selectAll && item.come_thaiwarehouse) {
          // eslint-disable-next-line no-param-reassign
          item.selectItem = true;
        } else {
          // eslint-disable-next-line no-param-reassign
          item.selectItem = false;
        }
        return item;
      });
    },
    DeleteBox() {
      this.payData = []
      this.items = this.items.map((item) => {
        item.selectItem = false;
        return item;
      })
      this.selectAll = false
    },
    isIndeterminateItem(item) {
      if (item) {
        const existingItem = this.payData.find(
          (existing) => existing._id === item._id
        );

        if (!existingItem) {
          if (item.selectItem) {
            this.payData.push(item);
          }
        } else {
          if (!item.selectItem) {
            this.payData = this.payData.filter(
              (existing) => existing._id !== item._id
            );
          }
        }
      }
    },
    EditType(item) {
      // eslint-disable-next-line no-underscore-dangle
      this.$router.push({ name: "tracking-edit", params: { id: item._id } });
    },
    countproducts() {
      return this.items.reduce((total, item) => total + item.product_amount, 0);
    },
    countweight() {
      return this.items.reduce(
        (total, item) => total + item.weight * item.product_amount,
        0
      );
    },
    countwidth() {
      return this.items.reduce((total, item) => total + item.width, 0);
    },
    countheight() {
      return this.items.reduce((total, item) => total + item.height, 0);
    },
    countproductLong() {
      return this.items.reduce((total, item) => total + item.long, 0);
    },
    countquantity() {
      return this.items.reduce(
        (total, item) => total + item.charge_cue * item.product_amount,
        0
      );
    },
    // CommasCue(x) {
    //   if (!x) {
    //     return '0'
    //   }
    //   const parts = x.toString().split('.')
    //   parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')

    //   if (parts[1]) {
    //     parts[1] = parseFloat(`0.${parts[1]}`).toFixed(4).split('.')[1]
    //   } else {
    //     parts.push('0000')
    //   }
    //   return parts.join('.')
    // },
    countserviceFee() {
      return this.items.reduce(
        (total, item) =>
          total +
          item.product_inspection_fee +
          item.shipping_fee +
          item.re_wrap_fee +
          item.packaging_fee +
          item.other,
        0
      );
    },
    conutkilo() {
      return this.items.reduce(
        (total, item) =>
          total +
          (item.discount && item.charge_per_kilo === item.charge_per_save
            ? (item.charge_per_save * (100 - item.discount)) / 100
            : item.charge_per_kilo === item.charge_per_save
            ? item.charge_per_save
            : 0),
        0
      );
    },
    Createpayment() {
      const selectItems = this.payData.filter((ele) => ele.selectItem);
      if (selectItems.length) {
        localStorage.setItem("paymentItems", JSON.stringify(selectItems));
        this.$router.push({
          name: "payment-paycreate",
          params: { id: selectItems[0].uid },
        });
      } else {
        this.Alert(false, "เลือกพัสดุ");
      }
    },
    Alert(status, title, mes) {
      this.$swal({
        icon: `${status ? "warning" : "error"}`,
        title: `<h3 style="color: #141414">${title}</h3>`,
        text: mes,
        customClass: {
          confirmButton: "btn btn-warning",
        },
      });
    },
    conutcue() {
      return this.items.reduce(
        (total, item) =>
          total +
          (item.discount && item.charge_per_cue === item.charge_per_save
            ? (item.charge_per_save * (100 - item.discount)) / 100
            : item.charge_per_cue === item.charge_per_save
            ? item.charge_per_save
            : 0),
        0
      );
    },
    CancelOrder(ID) {
      this.$swal({
        title: "ยืนยันที่จะยกเลิกรายการ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.show = true;
          const params = {
            // eslint-disable-next-line no-underscore-dangle
            ID,
          };
          this.$http
            .post("/order/cancelOrder", params)
            // eslint-disable-next-line no-unused-vars
            .then((response) => {
              // console.log(response.data)
              this.Success("ยกเลิกรายการสำเร็จ");
              this.show = false;
              this.GetNotify();
              this.GetOrderAll();
            })
            .catch((error) => {
              this.SwalError(error.response.data.message);
              this.show = false;
            });
        } else {
          this.$swal("ยกเลิก", "ยกเลิกคำสั่ง !", "error");
        }
      });
    },
    SubmitKeyword(id, keyword) {
      const obj = {
        id,
        keyword,
      };
      this.$http
        .post("/CustomerPayment/UpdateKeyword", obj)
        .then((response) => {})
        .catch((err) => {
          console.log(err);
        });
    },
    async GetOrderAll(type) {
      try {
        this.show = true;
        const params = {
          page: this.currentPage,
          perPage: this.perPage,
          type,
        };
        const { data: res } = await this.$http.get("/order/OrderByIdCus", {
          params,
        });
        this.items = res.data;
        console.log(res.data);
        this.totalRows = res.total;
        this.isActive = type;
        this.GetNotify();
        this.show = false;
      } catch (error) {
        console.log(error);
      }
    },
    GetOrderDetail() {
      const params = {
        ID: this.$route.params.id,
      };
      this.$http
        .post("/order/OrderById", params)
        .then((response) => {
          // console.log(response.data)
          this.details = response.data;

          console.log(this.details);
        })
        .catch((error) => console.log(error));

      console.log(this.$route.params.id);
    },
    GetNotify() {
      this.$http
        .get("/order/NotifyByCus")
        .then((response) => {
          // console.log(response.data)
          this.total = response.data.total;
          this.waitingforcheck = response.data.waitingforcheck;
          this.waitingforpayment = response.data.waitingforpayment;
          this.paid = response.data.paid;
          this.OrderSuccess = response.data.OrderSuccess;
          this.completetrack = response.data.completetrack;
          this.completewarehouse = response.data.completewarehouse;
          this.cancel = response.data.cancel;
        })
        .catch((error) => console.log(error));
    },
    resize(event) {
      // eslint-disable-next-line no-param-reassign
      event.target.style.height = "auto";
      // eslint-disable-next-line no-param-reassign
      event.target.style.height = `${event.target.scrollHeight}px`;
    },
    showModal(data) {
      this.pic = data;
      this.$refs["my-modal"].show();
    },
    downloadImg() {
      const imageURL = "@/assets/images/anan-img/example/1.jpg";
      const link = document.createElement("a");
      link.href = imageURL;
      link.download = "image.jpg";
      link.click();
    },
    Success(mes) {
      this.$swal({
        icon: "success",
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: "btn btn-success",
        },
      });
    },
    SwalError(mes) {
      this.$swal({
        icon: "error",
        title: `<h3 style="color: #141414">${this.$t("key-298")}</h3>`,
        text: mes,
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    },
  },
};
</script>

  <style lang="css" scoped>
.text-dang {
  font-size: 22px !important;
}

@media only screen and (max-width: 600px) {
  .text-dang {
    font-size: 15px !important;
  }
}
</style>
